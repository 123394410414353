var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"top":true,"color":_vm.color,"timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.snackbar_msg != null)?_c('span',[_vm._v(_vm._s(_vm.snackbar_msg))]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('v-row',[_c('div',{staticStyle:{"position":"fixed","z-index":"1","background-color":"white","width":"100%","top":"55px"}},[_c('v-breadcrumbs',{staticStyle:{"font-weight":"600","letter-spacing":"1px"},attrs:{"items":_vm.items,"large":""}})],1)]),_c('br'),_c('br'),_c('br'),_c('section',{staticClass:"bg-color-"},[_c('div',{staticClass:"container"},[[(_vm.init_loading)?_c('div',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"three-line,  article"}},'v-skeleton-loader',_vm.attrs,false))],1):_c('div',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.data_list,"fields":_vm.all_,"worksheet":"Student Data","name":"Student Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}])},[_c('span',[_vm._v("Import to Excel")])])],1)]],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data_list,"search":_vm.search},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"gray","dark":""},on:{"click":function($event){return _vm.getdetails(item)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.total)+" ")])]}}],null,true)},[_c('span',[_vm._v(" View Details")])])]}}])})],1)],1)]],2)]),[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog_details),callback:function ($$v) {_vm.dialog_details=$$v},expression:"dialog_details"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"gray"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog_details = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.org_name))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog_details = false}}},[_vm._v(" Close ")])],1)],1),_c('br'),_c('v-card-text',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_),callback:function ($$v) {_vm.search_=$$v},expression:"search_"}}),_c('v-spacer'),[_c('download-excel',{attrs:{"data":_vm.details_list,"fields":_vm.single_,"worksheet":"Student Data","name":"Student Data.xls"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","size":"40","left":""}},'v-icon',attrs,false),on),[_vm._v("mdi-file-excel")])]}}])},[_c('span',[_vm._v("Import to Excel")])])],1)]],2),_c('v-data-table',{attrs:{"headers":_vm.headers_,"items":_vm.details_list,"search":_vm.search_}})],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }